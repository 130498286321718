<template>
  <div
    class="modal fade"
    id="modalAccountEstimated"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Pembayaran Hutang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Akun Perkiraan </label>
              <input
                type="text"
                class="form-control"
                readonly
                placeholder="Masukkan Akun Perkiraan"
                v-model="formModal.nama"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nilai </label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  :readonly="!isEditable"
                  id="inlineFormInputGroupUsername"
                  placeholder="Masukan Nilai"
                  v-money="configMoney"
                  v-model.lazy="formModal.nilai"
                />
              </div>
            </div>
            <!-- <div class="form-group d-flex align-items-center">
                            <label class="form-label">Departemen </label>
                            <Select2 v-model="Departemen" :options="optionDepartemen" style="width:100% !important"
                                placeholder="Pilih Departemen" :settings="{}" @change="myChangeEvent($event)"
                                @select="mySelectEvent($event)" />
                        </div> -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveDetail"
              :disabled="!isEditable"
              class="btn btn-save"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Pembayaran Hutang Usaha</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-hutang">Hutang Usaha</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{
                        name: 'DetailAccountPayable',
                        params: { id: this.id },
                      }"
                      >Detail Hutang Usaha</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Pembayaran
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Pembayaran Hutang Usaha</div>
                <div class="sub-title">Edit informasi yang diperlukan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Nomor Invoice</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="formData.no_po"
                    placeholder="Nomor Invoice"
                  />
                </div>

                <div class="form-group">
                  <label>Nominal Pembayaran</label>
                  <input
                    type="text"
                    disabled
                    v-model="formData.nilai"
                    class="form-control"
                    placeholder="Nominal Pembayaran"
                  />
                </div>

                <div class="form-group">
                  <label>Kas & Bank</label>
                  <Select2
                    v-model="formData.id_akun"
                    :options="OptionKasBank"
                    placeholder="Pilih akun perkiraan..."
                    @change="formChange('id_akun')"
                    @select="formChange('id_akun')"
                    :class="{ 'is-invalid': formError && formError.id_akun }"
                    :settings="{
                      templateResult: formatState,
                    }"
                  />
                  <div class="form-error" v-if="formError && formError.id_akun">
                    {{ formError.id_akun }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Nomor Referensi :</label>
                  <input
                    type="text"
                    :class="{
                      'is-invalid': formError && formError.no_referensi,
                    }"
                    @keyup="formChange('no_referensi')"
                    class="form-control"
                    v-model="formData.no_referensi"
                    placeholder="Masukkan Nomor Referensi"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.no_referensi"
                  >
                    {{ formError.no_referensi }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Tanggal Pembayaran :</label>
                  <Datepicker
                    :autoApply="true"
                    :class="{
                      'is-invalid': formError && formError.tanggal_pembayaran,
                    }"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_pembayaran"
                    placeholder="Tanggal Pembayaran"
                :maxDate="new Date()"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    @cleared="changeDate('', 'tanggal_pembayaran')"
                    @update:modelValue="
                      changeDate($event, 'tanggal_pembayaran')
                    "
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.tanggal_pembayaran"
                  >
                    {{ formError.tanggal_pembayaran }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="title">Rincian Pembayaran</div>
                <div class="sub-title">Rincian yang ada pada Pembayaran</div>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <div class="search">
                <div class="form-group" style="width: 400px">
                  <Select2
                    v-model="AccountEstimated"
                    v-if="false"
                    :options="OptionAccountEstimated"
                    placeholder="Pilih akun perkiraan..."
                    :settings="{
                      templateResult: formatState,
                    }"
                    @change="myChangeEvent($event)"
                    @select="mySelectAccountEstimated($event)"
                  />
                </div>
              </div>
              <!-- <div class="card-total">
                                    <div class="total-debit d-flex flex-column">
                                        <span>Nilai</span>
                                        <span class="ml-auto"> Rp {{ (this.formData.nilaiData) ? this.formData.nilaiData : 0}}</span>
                                    </div>
                                </div> -->
            </div>
            <div v-if="formError && formError.nilaiData">
              <div
                class="form-error"
                :style="{ marginBottom: '5px', fontSize: '15px' }"
              >
                {{ formError.nilaiData }}
              </div>
            </div>

            <div class="table-responsive">
              <table
                class="table table-bordered table-striped"
                id="tableDepartment"
              >
                <thead>
                  <tr>
                    <th style="width: 15%">Akun</th>
                    <th style="width: 20%">Nama Akun</th>
                    <th style="width: 20%">Nilai</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr v-for="(value, index) in formDetail" :key="index" @click="editDetail(index, value.akun, value.nama, value.nilai, $event)" :style="{cursor: 'pointer'}"> -->
                  <tr v-for="(value, index) in formDetail" :key="index">
                    <td>{{ value.akun }}</td>
                    <td>{{ value.nama }}</td>
                    <td class="text-right position-relative">
                      {{ formData.nilai }}
                      <!-- <input type="text" class="form-control text-right" v-money="configMoney" @keyup="countDetail()" v-model.lazy="formDetail[index].nilai" placeholder="Masukkan nilai"> -->
                      <!-- <img src="/img/avatar/icon-delete.svg" alt="" class="btn-add-row" style="position: absolute;right: 10px;width: 20px;top: 50%;transform: translateY(-50%);cursor: pointer;"/> -->
                    </td>
                  </tr>
                  <tr v-if="formDetail.length === 0">
                    <td colspan="3" class="text-center">
                      No data available in table
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Datepicker from "vue3-date-time-picker";
import $ from "jquery";
import { ref } from "vue";
import { loadScript } from "vue-plugin-load-script";
import { checkRules, cksClient, showAlert } from "../../../helper";
import moment from "moment";
import "moment/locale/id";
import { get_AkunList } from "../../../actions/akun_perkiraan";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");

import { VMoney } from "v-money";
import { maska } from "maska";
import {
  get_HutangInvoiceGet,
  post_HutangSavePembayaran,
} from "../../../actions/hutang";

export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
  },

  directives: {
    money: VMoney,
    maska,
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    // this.FormatState()
    //  this.myChangeEvent()
    //  this.mySelectEvent()
  },

  data() {
    return {
      myValue: "aaaa",
      OptionAccountEstimated: [],
      id: "0",
      id_company: cksClient().get("_account").id_company,
      no_po: this.$route.params.id,
      formModal: {
        key: "",
        id: "",
        nama: "",
        nilai: "",
      },
      onModal: false,
      formDetail: [],
      bebanAkun: "",
      formError: [],
      formData: {
        no_transaksi: "",
        no_po: this.$route.params.id ? this.$route.params.id : "",
        tanggal_pembayaran: "",
        no_referensi: "",
        status: "aktif",
        nilai: 0,
        nilaiData: 0,
        id_akun: "",
      },
      rules: {
        no_po: {
          required: true,
        },
        no_referensi: {
          required: false,
        },
        tanggal_pembayaran: {
          required: true,
        },
        id_akun: {
          required: true,
        },
      },
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      isSubmit: false,
      isEditable: true,
      OptionKasBank: [],
    };
  },

  created() {
    this.getData();
    this.getKasBank(1);
  },
  methods: {
    getData() {
      get_HutangInvoiceGet(
        this.no_po,
        (res) => {
          if (!res.is_success) {
            showAlert(this.$swal, {
              title: "WARNING!",
              msg: "Data Invoice tidak ditemukan",
              showCancelButton: true,
              showConfirmButton: false,
              onCancel: () => {
                this.$router.push({
                  name: "DataCredit",
                });
              },
            });
          }
          this.formData.nilai = this.formatMoney(res.data.nilai);
          this.id = res.data.id_hutang;
          this.bebanAkun = res.data.nama_akun;
          this.formDetail = res.jurnal_data;
        },
        () => {
          showAlert(this.$swal, {
            title: "WARNING!",
            msg: "Data Invoice tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: true,
            onCancel: () => {
              this.$router.push({
                name: "DataCredit",
              });
            },
          });
        }
      );
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
    //MAS
    getKasBank(tipe = "") {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          // console.log(data);
          if (tipe == 2 || tipe == 1) {
            this.OptionKasBank = data;
          } else {
            this.OptionAccountEstimated = data;
          }
        }
      );
    },
    changeDate(data, key) {
      if (data) {
        this.formData[key] = moment(data).format("YYYY-MM-DD");
      } else {
        this.formData[key] = "";
      }
      this.formChange(key);
    },
    async formChange(val) {
      if (Object.hasOwnProperty.call(this.rules, val)) {
        this.rules[val].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    saveDetail() {
      if (this.formModal.nilai) {
        if (this.formModal.key !== "") {
          this.formDetail[this.formModal.key] = {
            akun: this.formModal.id,
            nama: this.formModal.nama,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          };
        } else {
          this.formDetail.push({
            akun: this.formModal.id,
            nama: this.formModal.nama,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          });
        }
        this.countDetail();
        $("#modalAccountEstimated").modal("hide");
      }
    },
    editDetail(key, id, nama, nilai, ev) {
      if (ev.target && ev.target.className === "btn-add-row") {
        this.deleteDetail(key);
      } else {
        this.formModal.key = key;
        this.formModal.id = id;
        this.formModal.nilai = nilai;
        this.formModal.nama = nama;
        $("#modalAccountEstimated").modal("show");
      }
    },
    deleteDetail(key) {
      delete this.formDetail[key];
      this.formDetail.length -= 1;
      this.countDetail();
    },
    countDetail() {
      var nilai = 0;
      for (const key in this.formDetail) {
        if (Object.hasOwnProperty.call(this.formDetail, key)) {
          const element = this.formDetail[key];
          nilai += parseInt(element.nilai);
        }
      }
      this.formData.nilaiData = this.formatMoney(nilai);
      this.formChange();
    },
    mySelectAccountEstimated(ev) {
      if (ev.id) {
        this.onModal = true;
        this.formModal.key = "";
        this.formModal.id = ev.id;
        this.formModal.nilai = "";
        this.formModal.nama = ev.text;
        $("#modalAccountEstimated").modal("show");
        setTimeout(() => {
          this.AccountEstimated = "";
        }, 500);
      }
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      this.formData.nilai = this.formatMoney(this.formData.nilai);
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        var dataForm = this.formData;
        dataForm.nilai = this.formData.nilai.split(".").join("");
        dataForm.jurnal_data = this.formDetail;
        this.isSubmit = true;
        post_HutangSavePembayaran(
          dataForm,
          (res) => {
            this.isSubmit = false;
            var msg =
              res.response_code === 201
                ? "Data pembayaran hutang berhasil dicatat"
                : "Data pembayaran hutang berhasil diperbarui";
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
              onSubmit: () => {
                localStorage.setItem("pembayaranSet", true);
                this.$router.push({
                  name: "DetailAccountPayable",
                  params: {
                    id: this.id,
                  },
                });
              },
            });
          },
          () => {
            this.isSubmit = false;
            showAlert(this.$swal, {
              title: "ERROR!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }
        );
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}
</style>
